const App = () => {
  return (
    <>
      <header className="w-full bg-[#262222]">
        <div className="mx-auto container flex flex-col sm:flex-row justify-between items-center py-6">
          <img src="/assets/images/logo.png" className="max-w-[60px] mb-6 sm:mb-0" alt="Logotipo" />

          <nav>
            <ul className="flex flex-col sm:flex-row justify-center text-center sm:justify-around space-y-1 sm:space-y-0 sm:space-x-6 text-white cinzel-regular text-sm">
              <li><a className="hover:underline" href="#home" title="Home">Home</a></li>
              <li><a className="hover:underline" href="#nosso-escritorio" title="Nosso Escritório">Nosso Escritório</a></li>
              <li><a className="hover:underline" href="#areas-atuacao">Áreas de Atuação</a></li>
              <li><a className="hover:underline" href="#contato">Contato</a></li>
            </ul>
          </nav>
        </div>
      </header>

      <div id="home">
        <div className="relative bg-gray-800 text-white">
          <img src="/assets/images/banner.jpg" alt="Banner" className="w-full h-80 object-cover" />

          <div className="absolute inset-0 flex flex-col justify-center items-start bg-black bg-opacity-60">
            <div className="px-4 sm:px-0 container mx-auto">
              <h1 className="text-4xl cinzel-bold">Advocacia especializada em Direito Criminal</h1>
              <p className="mb-4 text-md cinzel-regular">Nathalia Rosa Advocacia</p>
              <a href="https://api.whatsapp.com/send?phone=5513997264326" target="_blank" rel="noreferrer" className="px-6 py-2 bg-[#594642] hover:opacity-90 text-white rounded-[3px] montserrat-regular text-sm"><i className="fab fa-whatsapp inline-block mr-2"></i> Entre em contato</a>
            </div>
          </div>
        </div>
      </div>

      <div id="nosso-escritorio">
        <div className="container mx-auto py-12 px-4 sm:px-0 flex flex-col md:flex-row justify-between items-start">
          <div className="">
            <h3 className="text-2xl mb-2 cinzel-bold">Nosso escritório</h3>
            <div className="w-32 h-1.5 bg-[#A67C6D] mb-8"></div>

            <p className="w-full md:w-2/3 text-justify montserrat-regular">Somos um escritório especializado em todos os ramos de direito, localizado em Mongaguá, mas com atuação em todo o território nacional. Sob a liderança da advogada Nathalia Batista Rosa, nossa equipe alia experiência e tecnologia de ponta para oferecer um atendimento personalizado, transparente e eficiente. Com foco na defesa dos direitos de nossos clientes, buscamos sempre as melhores estratégias jurídicas, estabelecendo uma relação de confiança essencial para o sucesso dos nossos trabalhos.</p>
          </div>

          <div className="md:min-w-[300px] max-w-full relative mt-8 md:mt-0">
            <div className="absolute -bottom-2 -left-2 bg-[#8C4C35] w-full h-full z-0"></div>
            <img src="/assets/images/escritorio.jpg" alt="Imagem: Nosso Escritório" className="relative z-10" />
          </div>

          {/* <p>Localizado em Praia Grande, nosso escritório de advocacia se destaca pela especialização em Direito Criminal, oferecendo serviços em todo o território nacional. Com uma equipe de advogados altamente qualificados, comprometidos com a excelência jurídica, estamos preparados para atender nossos clientes de maneira eficaz e personalizada.</p>
          <p>Investimos em tecnologia de ponta para proporcionar um atendimento ágil e seguro, utilizando ferramentas que permitem o envio de relatórios detalhados, atualizações processuais e documentos de forma personalizada e confidencial. Nosso objetivo é garantir que cada cliente tenha um acompanhamento transparente e contínuo, reforçando a confiança mútua essencial para o sucesso de nossos trabalhos.</p>
          <p>Nossa fundadora, Nathalia Rosa, é formada pela renomada Universidade Santa Cecília - UNISANTA e possui especialização em Direito Penal e Direito Público. Sua liderança inspira nossa equipe a buscar sempre as melhores estratégias de defesa, com ética, dedicação e transparência, assegurando a proteção dos direitos de nossos clientes.</p>
          <p>Nosso compromisso é defender seus direitos com seriedade, oferecendo soluções jurídicas sob medida para cada caso, sempre com foco na excelência e na justiça.</p> */}
        </div>
      </div>

      <div id="areas-atuacao" className="bg-[#D9A38F10] w-full">
        <div className="container mx-auto py-12 px-4 sm:px-0">
          <div className="w-32 h-1.5 bg-[#8C4C35] mb-4"></div>
          <h3 className="text-2xl text-[#262222] cinzel-bold pb-6 sm:pb-12">Áreas de atuação</h3>

          <p className="mb-8 montserrat-regular">Em nosso escritório, oferecemos serviços jurídicos especializados em diversas áreas do direito. Nossa equipe está comprometida em fornecer soluções eficazes e personalizadas para atender às necessidades de nossos clientes, sempre com ética e dedicação.</p>

          <div className="sm:grid sm:grid-cols-2 xl:grid-cols-3 space-y-8 sm:space-y-0 sm:gap-8">
            <div className="relative">
              <div className="bg-[#8C4C35] w-full h-full z-0 absolute -top-2 -right-2"></div>
              <div className="border border-[#8C4C35] p-10 z-10 relative bg-white">
                <h4 className="text-lg pb-2 cinzel-semibold">Direito Criminal</h4>
                <p className="text-left montserrat-regular min-h-[100px] xl:min-h-[140px]">Defendemos os direitos de acusados em processos criminais, assegurando uma defesa estratégica e a proteção de suas garantias fundamentais.</p>
              </div>
            </div>

            <div className="relative">
              <div className="bg-[#8C4C35] w-full h-full z-0 absolute -top-2 -right-2"></div>
              <div className="border border-[#8C4C35] p-10 z-10 relative bg-white">
                <h4 className="text-lg pb-2 cinzel-semibold">Direito de Família</h4>
                <p className="text-left montserrat-regular min-h-[100px] xl:min-h-[140px]">Oferecemos apoio em questões familiares, como divórcios e guarda de filhos, buscando soluções harmoniosas e respeitando os vínculos familiares.</p>
              </div>
            </div>

            <div className="relative">
              <div className="bg-[#8C4C35] w-full h-full z-0 absolute -top-2 -right-2"></div>
              <div className="border border-[#8C4C35] p-10 z-10 relative bg-white">
                <h4 className="text-lg pb-2 cinzel-semibold">Direito Médico e da Saúde</h4>
                <p className="text-left montserrat-regular min-h-[100px] xl:min-h-[140px]">Atuamos na defesa de profissionais da saúde e pacientes, lidando com questões de responsabilidade médica e proteção dos direitos na área da saúde.</p>
              </div>
            </div>

            <div className="relative">
              <div className="bg-[#8C4C35] w-full h-full z-0 absolute -top-2 -right-2"></div>
              <div className="border border-[#8C4C35] p-10 z-10 relative bg-white">
                <h4 className="text-lg pb-2 cinzel-semibold">Direito do Consumidor</h4>
                <p className="text-left montserrat-regular min-h-[100px] xl:min-h-[140px]">Protegemos os direitos dos consumidores em conflitos com produtos e serviços, promovendo transparência e combatendo práticas abusivas.</p>
              </div>
            </div>

            <div className="relative">
              <div className="bg-[#8C4C35] w-full h-full z-0 absolute -top-2 -right-2"></div>
              <div className="border border-[#8C4C35] p-10 z-10 relative bg-white">
                <h4 className="text-lg pb-2 cinzel-semibold">Direito Cível</h4>
                <p className="text-left montserrat-regular min-h-[100px] xl:min-h-[140px]">Prestamos consultoria e representação em litígios civis, com foco na responsabilidade civil e na resolução eficiente de disputas.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="contato">
        <div className="container mx-auto px-4 sm:px-0 flex flex-col md:flex-row items-center justify-start py-12">
          <div className="mb-4 md:mb-0 md:mr-12">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3642.267922097622!2d-46.62026642480617!3d-24.092071781303765!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce271388a0d9e7%3A0x5a50b17bfb6a17ae!2sAv.%20Marina%2C%2020%20-%20Centro%2C%20Mongagu%C3%A1%20-%20SP%2C%2011730-000!5e0!3m2!1spt-BR!2sbr!4v1723831316036!5m2!1spt-BR!2sbr" className="w-full md:w-[400px] md:h-[400px]" title="mapa" style={{ border: 0 }} allowFullScreen={true} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
          </div>
          <div>
            <address className="font-normal mb-4">
              <h5 className="text-xl cinzel-semibold mb-4">Endereço</h5>
              <p className="montserrat-regular">
                Avenida Marina, 20 - Centro<br />
                Mongaguá / SP
                CEP: 11730-000
              </p>
            </address>
            <h3 className="text-2xl cinzel-bold mb-4">Entre em contato</h3>
            <div className="space-y-3">
              <p className="montserrat-regular"><i className="fa fa-phone-alt text-sm"></i> <a href="mailto:nathalia.rvg@gmail.com" rel="noreferrer" target="_blank" title="Enviar E-mail para nathalia.rvg@gmail.com" className="ml-1">nathalia.rvg@gmail.com</a></p>
              <p className="montserrat-regular"><i className="fab fa-whatsapp text-md"></i> <a href="https://api.whatsapp.com/send?phone=5513997264326" target="_blank" rel="noreferrer" className="ml-1">+55 13 99726-4326</a></p>
              <p className="montserrat-regular"><i className="fab fa-instagram text-md"></i> <a href="https://www.instagram.com/dranathaliarosa/" rel="noreferrer" target="_blank" className="ml-1">@dranathaliarosa</a></p>
            </div>
          </div>
        </div>
      </div>
      <footer className="bg-[#262222] text-white montserrat-regular text-xs text-center py-4">
        &copy; Nathalia Rosa - Advocacia 24h, Direito Civil, Direito do Consumidor, Direito da Família e Sucessões e Direito Criminal
      </footer>

      <a href="https://api.whatsapp.com/send?phone=5513997264326" target="_blank" rel="noreferrer" className="w-14 h-14 rounded-full bg-[#25d366] fixed z-20 flex items-center justify-center text-white text-2xl bottom-4 right-4 hover:shadow-lg transition-all"><i className="fab fa-whatsapp"></i></a>
    </>
  );
};
export default App;
